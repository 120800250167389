<template>
  <div id="WaterCharts"></div>
</template>

<script>
export default {
  props: {
    chartData: {
      type: Object,
      required: true
    },
    tooltip: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      WaterCharts: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      }
    }
  },
  created() {
    // console.log(this.chartData);
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.WaterCharts) {
      return;
    }
    this.WaterCharts.dispose();
    this.WaterCharts = null;
  },
  destroyed() {
    window.removeEventListener("resize", () => {});
  },
  methods: {
    initChart() {
      // 基于准备好的dom，初始化echarts实例
      this.WaterCharts = this.$echarts.init(
        document.getElementById("WaterCharts")
      );
      window.addEventListener("resize", () => {
        this.WaterCharts.resize();
      });
      // 绘制图表
      this.setOptions(this.chartData);
    },
    setOptions({ dataX, dataY, dataZ } = {}) {
      this.WaterCharts.setOption({
        tooltip: { ...this.tooltip },
        // tooltip: {
        //   trigger: 'axis',
        //   formatter: '{b} 时：{c} cm'
        // },
        xAxis: {
          name: dataZ,
          data: dataX,
          type: "category",
          // boundaryGap: false, //x轴从零开始
          // x轴上的字体颜色样式
          axisLabel: {
            show: true,
            textStyle: {
              color: "#C7C7C7"
            }
          },
          // x轴上颜色样式
          axisLine: {
            lineStyle: {
              color: "#C7C7C7",
              width: 1 //这里是坐标轴的宽度
            }
          },
          axisTick: {
            //x轴刻度线
            show: false
          }
        },
        yAxis: {
          name: "单位/cm",
          type: "value",
          // y轴上的字体颜色样式
          axisLabel: {
            show: true,
            textStyle: {
              color: "#C7C7C7"
            }
          },
          // y轴上颜色样式
          axisLine: {
            lineStyle: {
              color: "#C7C7C7",
              width: 1 //这里是坐标轴的宽度
            }
          },
          axisTick: {
            //y轴刻度线
            show: false
          }
        },
        grid: {
          top: "24%",
          left: "5%",
          bottom: "18%",
          right: "7%"
        },
        series: [
          {
            data: dataY,
            type: "line"
            // itemStyle: { normal: { label: { show: true } } }
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#WaterCharts {
  width: 100%;
  height: 100%;
}
</style>
